<script lang="ts" setup>
defineProps<{
  show: boolean
  project: object | null
}>()

const emit = defineEmits(['close'])
</script>

<template>
  <UiModal :is-open="show" class="bg-white" @close="emit('close')">
    <div class="space-y-4 font-display">
      <div class="space-y-2">
        <UIcon
          name="i-heroicons-lock-closed"
          class="w-10 h-10 text-forgd-primary-300"
        />
        <h1 class="text-xl font-semibold ">
          This action can only be performed by the user with token admin role.
        </h1>
        <p class="text-forgd-gray-600 text-sm">
          Your role on {{ project?.ticker || project?.name }} is teammate. Teammates have read-only access at the moment on playbook activities and tools. If you would like to perform modifications please contact the admin of the token directly.
        </p>
      </div>
      <div>
        <UButton :ui="{ color: { white: { solid: 'justify-center' } } }" color="white" class="w-full" size="xl" @click="emit('close')">
          Got it
        </UButton>
      </div>
    </div>
  </UiModal>
</template>
